<template>
  <section class="contact-1 mt-5" data-bg-img="/images/pattern/02.png">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-7">
          <div class="section-title mb-2">
            <h2 class="title">Contact Us</h2>
          </div>
          <div class="contact-main">
            <form
              id="contact-form"
              class="row g-4 needs-validation"
              method="post"
              action="php/contact.php"
              novalidate
            >
              <div class="messages"></div>
              <div class="col-md-6 mb-3">
                <input
                  id="form_name"
                  type="text"
                  name="name"
                  class="form-control"
                  placeholder="Name"
                  required
                />
                <div class="invalid-feedback">Name is required.</div>
              </div>
              <div class="col-md-6 mb-3">
                <input
                  id="form_email"
                  type="email"
                  name="email"
                  class="form-control"
                  placeholder="Email"
                  required
                />
                <div class="invalid-feedback">Valid email is required.</div>
              </div>
              <div class="col-md-12 mb-3">
                <input
                  id="form_phone"
                  type="tel"
                  name="phone"
                  class="form-control"
                  placeholder="Phone"
                  required
                />
                <div class="invalid-feedback">Phone is required</div>
              </div>
              <div class="col-md-12">
                <textarea
                  id="form_message"
                  name="message"
                  class="form-control mb-3"
                  placeholder="Message"
                  rows="3"
                  required
                ></textarea>
                <div class="invalid-feedback">Please,leave us a message.</div>
              </div>
              <div class="col-md-12">
                <button class="btn btn-theme btn-radius">
                  <span>Send Message</span>
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-5 col-md-5 ms-auto mt-5 mt-md-0">
          <ul class="contact-info list-unstyled">
            <li class="mb-4">
              <i class="flaticon-location"></i><span>Address:</span>
              <p>Ground Floor, Utalii House, Utalii Street, Nairobi, Kenya</p>
              <p>PO Box 4058, 00506, Nairobi</p>
            </li>
            <li class="mb-4">
              <i class="flaticon-email"></i><span>Email</span
              ><a href="mailto:support@tradesk.co.ke">support@tradesk.co.ke</a>
            </li>
            <li class="mb-4">
              <i class="flaticon-call"></i><span>Phone:</span
              ><a href="tel:+254718844831">+254 718 844 831</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
